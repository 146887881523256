import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Parallax from '../components/parallax'
import Logos from '../components/logos'

const ProductsPage = () => {
  return (
    <Layout title="Products">
      <Parallax background="products-bd.png" componentStyle="products" />
      <div data-aos="fade-up" data-aos-duration="2000">
        <div className="container ">
          <div className="row">
            <div className="col-md-12">
              <div className="description">
                <h3 className="text-center header-title">Products</h3>
                <p>
                  หากองค์กรของคุณ กำลังมองหา AntiVirus หรือ
                  บริการความมั่นคงปลอดภายทางไซเบอร์ AW Secure Soft
                  เป็นตัวแทนจำหน่ายผลิตภัณฑ์จากแบรนด์ชั้นนำระดับโลก ทั้ง
                  Hardware และ Software ทุกประเภท
                  พร้อมผู้เชี่ยวชาญที่จะแนะนำผลิตภัณฑ์ให้เหมาะกับองค์กรของคุณ
                </p>
                <div className="row">
                  <div className="col-sm-4 col-sm-offset-2 col-md-4 col-md-offset-2">
                    <h4>Hardware</h4>
                    <ul className="list-unstyled">
                      <li>Next-Gen Firewall</li>
                      <li>Switch</li>
                      <li>PC</li>
                    </ul>
                  </div>
                  <div className="col-sm-4 col-sm-offset-2 col-md-4 col-md-offset-2">
                    <h4>Software</h4>
                    <ul className="list-unstyled">
                      <li>AntiVirus</li>
                      <li>Office 365</li>
                      <li>Virtualization</li>
                    </ul>
                  </div>
                </div>
                <Link to="/contact">
                  <button
                    type="button"
                    className="btn btn-success btn-fill btn-md center-block"
                  >
                    <i className="fa fa-phone" aria-hidden="true"></i>
                    {'   '}
                    ติดต่อเรา
                  </button>
                </Link>
                <div className="logos animate-frame">
                  <Logos animate={true} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="description">
                <h3 className="text-center header-title">Services</h3>
                <p>
                  บริการติดตั้งวางระบบ เครือข่าย Network, Security, ซ่อมเเซม,
                  ให้คำปรึกษา เเละเเก้ไขปัญหาหลังการขาย hotline support
                  ด้วยทีมงานมืออาชีพ
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProductsPage
